import { listings } from "@wunderflats/constants";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import date from "./date";
import d from "./dates";
import { isViennaListing } from "./vienna";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const isValidDateRange = ({ from, to, minimumBookingDuration = 1 }) => {
  // check if both values are valid dates
  if (!dayjs(from).isValid() || !dayjs(to).isValid()) {
    return false;
  }
  // check if from date is before today
  if (dayjs(from).isBefore(dayjs(), "days")) {
    return false;
  }
  // check if to date is before minimum booking duration
  if (
    dayjs(to).isBefore(
      dayjs(from).add(minimumBookingDuration, "months").subtract(1, "days"),
    )
  ) {
    return false;
  }

  return true;
};

/**
 * When we don't pass blocks or minBookingDuration, we do the basic checks, e.g: from date not before today.
 * If we pass the blocks and the minBookingDuration, we can get the availability of a SINGLE listing
 * The availability of multilistings can only be checked through /listings/g/${groupId}/availability
 * src/api/multilistingsApi.js - getAvailableListings(groupId, duration)
 * We can use this function to check if it's worth (it passes the basic checks) to make the api request
 */

export const fromToValidate = (
  dates,
  {
    blocks = [],
    t,
    minBookingDuration = 1,
    maxBookingDuration = null,
    availableFrom,
    country,
    zipCode,
  },
) => {
  const res = {};
  const from = dayjs.utc(dates.from).startOf("day");
  const to = dayjs.utc(dates.to).startOf("day");
  const minBookingDurationPlusFrom = dayjs
    .utc(from)
    .add(minBookingDuration, "months")
    .add(-1, "days")
    .startOf("day");

  const normalizedBlocks = blocks.map((block) => {
    return {
      from: dayjs.utc(block.from).startOf("day"),
      to: dayjs.utc(block.to).startOf("day"),
    };
  });

  const firstBlockPeriodAfterFrom = normalizedBlocks.find((block) =>
    from.isSameOrBefore(block.from),
  );

  const today = dayjs.utc().format("YYYY-MM-DD");

  if (!dates.from) {
    res.from = t("fromToValidate.missingValue");
  } else if (availableFrom && from.isBefore(availableFrom)) {
    res.from = t("fromToValidate.fromIsBeforeAvailableFrom");
  } else if (from.isBefore(today)) {
    res.from = t("fromToValidate.fromIsBeforeToday");
  } else if (
    // Check that the from date is not inside any block
    normalizedBlocks.some(
      (block) =>
        from.isSameOrAfter(block.from) && from.isSameOrBefore(block.to),
    )
  ) {
    res.from = t("fromToValidate.fromIsOnABlockPeriod");
  } else if (
    // Check that the interval between the from date and the beginning of the next block is larger than minBookingDuration
    firstBlockPeriodAfterFrom &&
    from.isAfter(
      dayjs
        .utc(firstBlockPeriodAfterFrom.from)
        .add(minBookingDuration * -1, "months"),
    )
  ) {
    res.from = t("fromToValidate.fromIsAfterBlockFromMinusMinBookingDuration");
  }

  if (!dates.from) {
    res.to = t("fromToValidate.fromDateIsMandatoryToKnowIfToIsAvailable");
    return res;
  }
  if (!dates.to) {
    res.to = t("fromToValidate.missingValue");
  } else if (
    isViennaListing({ country, zipCode }) &&
    to.isAfter(from.add(listings.MAX_BOOKING_DURATION.Vienna, "month"))
  ) {
    // We're only using this message to block the days in the date picker
    // TODO: Remove this when deploying max duration feature
    res.to = t("fromToValidate.bookingPeriodCannotExceedN", {
      maxBookingDuration: listings.MAX_BOOKING_DURATION.Vienna,
    });
  } else if (
    maxBookingDuration &&
    to.isAfter(from.add(maxBookingDuration, "month").subtract(1, "day"))
  ) {
    res.to = t("fromToValidate.bookingPeriodCannotExceedN", {
      maxBookingDuration,
    });
  } else if (to.isBefore(from)) {
    res.to = t("fromToValidate.toIsBeforeFrom");
  } else if (to.isBefore(minBookingDurationPlusFrom)) {
    res.to = t("fromToValidate.toIsSmallerThanFromPlusMinBookingDuration");
  } else if (
    normalizedBlocks.some(
      (block) => to.isSameOrAfter(block.from) && to.isSameOrBefore(block.to),
    )
  ) {
    res.to = t("fromToValidate.toIsOnABlockPeriod");
  } else if (
    firstBlockPeriodAfterFrom &&
    to.isAfter(firstBlockPeriodAfterFrom.from)
  ) {
    res.to = t("fromToValidate.thereIsABlockInBetweenFromAndTo");
  }

  return res;
};

/**
 * Since we only want the available dates to be selectable in the DateWidget,
 * this function makes sure that the "from" date is available
 */

export const isFromDateAvailable = ({
  blocks = [],
  date,
  minBookingDuration = 1,
  availableFrom,
}) => {
  if (!date) return;
  const dates = {
    from: date,
    to: "",
  };

  return !fromToValidate(dates, {
    blocks,
    t: (e) => e,
    minBookingDuration,
    availableFrom,
  }).from;
};

/**
 * Since we only want the available dates to be selectable in the DateWidget,
 * this function makes sure that the "to" date is available
 */

export const isToDateAvailable = ({
  from = dayjs.utc(),
  blocks = [],
  date,
  minBookingDuration = 1,
  maxBookingDuration = null,
  country,
  zipCode = "",
}) => {
  const dates = {
    from,
    to: date,
  };
  return !fromToValidate(dates, {
    blocks,
    t: (e) => e,
    minBookingDuration,
    maxBookingDuration,
    country,
    zipCode,
  }).to;
};

export const getEarliestAvailableDate = ({
  blocks = [],
  minBookingDuration = 1,
  availableFrom,
}) => {
  const from = dayjs.utc().startOf("day");
  const isAvailableInFuture =
    availableFrom && dayjs(availableFrom).isAfter(from, "day");

  if (isAvailableInFuture && blocks?.length === 0) {
    return dayjs(availableFrom).format("DD.MM.YYYY");
  }

  const isCurrentDateAvailable = isFromDateAvailable({
    blocks,
    date: from,
    minBookingDuration,
    availableFrom,
  });

  if (isCurrentDateAvailable) {
    return dayjs(from).format("DD.MM.YYYY");
  }

  const normalizedBlocks = blocks.map((block) => {
    return {
      from: dayjs.utc(block.from).startOf("day"),
      to: dayjs.utc(block.to).startOf("day"),
    };
  });
  const blockPeriod = normalizedBlocks.find(
    (block) =>
      from.isSameOrBefore(block.from) ||
      date.isBetween(block.from, from, block.to),
  );

  if (
    isAvailableInFuture &&
    dayjs(availableFrom).isBefore(blockPeriod.from) &&
    dayjs(blockPeriod.from).isSameOrAfter(
      dayjs(availableFrom).add(minBookingDuration, "months"),
    )
  ) {
    return dayjs(availableFrom).format("DD.MM.YYYY");
  }

  return blockPeriod?.to
    ? dayjs(blockPeriod.to).add(1, "day").format("DD.MM.YYYY")
    : null;
};

/**
 * @deprecated TODO: Remove after migrating to the new datepicker
 */
export function getMaxBookableDate({ fromDate, maxBookingDuration }) {
  return dayjs
    .utc(fromDate || dayjs.utc())
    .subtract(1, "day")
    .add(maxBookingDuration, "month")
    .toISOString();
}

export const isPeriodAvailable = ({
  availableFrom,
  availableTo,
  bookingFrom,
  bookingTo,
  blockedDurations,
}) => {
  const availableRange = { from: availableFrom, to: availableTo };
  const booking =
    bookingFrom && bookingTo ? { from: bookingFrom, to: bookingTo } : null;
  const available =
    !booking ||
    d.periodAvailableWithin(booking, availableRange, blockedDurations);

  return available;
};

export const intersects = ({ date, blockedDurations }) => {
  for (const blockedDuration of blockedDurations) {
    if (
      d.dateIntersectsBlock({
        date,
        block: blockedDuration,
      })
    ) {
      return true;
    }
  }

  return false;
};

export const isDateBlocked = ({ date, blockedDurations, available }) => {
  if (!date) return false;

  return !available || intersects({ date, blockedDurations });
};
